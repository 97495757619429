import {getCookie, setCookie} from "../utils/cookies";

/**
 *
 */
export default class Overlay {

	constructor() {
		// Close overlay on click outside container
		if ($('[data-overlay]').length > 0) {
			$(document).on('click',function(e) {
				let container = "[data-overlay-container]";
				if (!$(container).is(e.target) && $(e.target).closest(container).length === 0 && $('body').hasClass('hasOverlay')) {
					$('[data-overlay]').toggle();
					$('body').removeClass('hasOverlay');
				}
			});
		}

		if ($('[data-overlay="welcome"]').length > 0) {
			const config = getCookie('iris_nestor_config') ? JSON.parse(getCookie('iris_nestor_config')) : null;
			if(!config){
				$('body').addClass('hasOverlay');
				setCookie('iris_nestor_config', JSON.stringify({
					welcomeOverlay: true,
				}), 365);
			}
		}

		// OPEN / CLOSE System
		$('[data-toggle-overlay]').on('click', function() {
			let id = $(this).data('toggle-overlay');
			$('[data-overlay="' + id + '"]').toggle();
			$('body').toggleClass('hasOverlay');
		});
	}
}
