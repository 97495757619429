import Turbolinks from 'turbolinks/dist/turbolinks';

export default class Ux {

    constructor()
    {
        Ux.ripple_effect();
        Ux.turbolinks();
    }

    static ripple_effect()
    {
        const rippleElements = document.querySelectorAll( '[data-ripple-effect], .button' );

        rippleElements.forEach( rippleElement => rippleElement.addEventListener( 'click', ( e ) => {

            let X = 0,
                Y = 0;

            // If click on child item
            if ( e.target !== e.currentTarget ) {
                let obj = e.currentTarget;
                if ( obj.offsetParent ) {
                    X = obj.offsetLeft;
                    Y = obj.offsetTop;
                    while ( obj = obj.offsetParent ) {
                        X += obj.offsetLeft;
                        Y += obj.offsetTop;
                    }
                }

                X = e.pageX - X;
                Y = e.pageY - Y;
            } else {
                //Else if click on item data-ripple-effect
                X = e.offsetX;
                Y = e.offsetY;
            }

            let rippleDiv = document.createElement( "span" );

            rippleDiv.classList.add( 'ripple' );
            rippleDiv.setAttribute( "style", "top:" + Y + "px; left:" + X + "px;" );
            let customColor = e.currentTarget.getAttribute( 'data-ripple-color' );
            if ( customColor ) {
                rippleDiv.style.background = customColor;
            }
            e.currentTarget.appendChild( rippleDiv );
            setTimeout( function () {
                rippleDiv.parentElement.removeChild( rippleDiv );
            }, 900 );
        } ) );
    }

    static turbolinks()
    {
        Turbolinks.start();
        Turbolinks.setProgressBarDelay( 100 );

        $( document ).on( 'turbolinks:click', function ( e ) {
            const url = e.originalEvent.data.url;

            if ( (Theme.serverURL === url) || url.indexOf('#') != -1 ) {
                return false;
            } else {
                $( 'body' )
                    .addClass( 'animated fadeOut' )
                    .off( 'webkitAnimationEnd oanimationend msAnimationEnd animationend' );
            }
        } );

        $( document ).on( 'wpiris-click', function ( e ) {
            $( 'body' )
                .addClass( 'animated fadeOut' )
                .off( 'webkitAnimationEnd oanimationend msAnimationEnd animationend' );
        } );

        $( document ).on( 'turbolinks:load', function ( e ) {
            if ( e.originalEvent.data.timing.visitStart ) {
                $( 'body' )
                    .addClass( 'animated fadeIn' )
                    .one( 'webkitAnimationEnd oanimationend msAnimationEnd animationend', function () {
                        $( 'body' ).removeClass( 'animated' );
                    } );

            } else {
                $( 'html' ).removeClass( 'animated' );
            }
        } );

        Turbolinks.BrowserAdapter.prototype.reload = function () {
            window.scrollTo(0, 0)
            window.location.reload()
        }
    }
}
