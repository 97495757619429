import '@fancyapps/fancybox/dist/jquery.fancybox.min';
import Modernizr from 'modernizr';
import LazyLoad from "vanilla-lazyload";
import Turbolinks from 'turbolinks/dist/turbolinks';
import mobiscroll from '@mobiscroll/javascript/dist/js/mobiscroll.javascript.min';

export default class Utils {
    
    constructor()
    {
        Utils.click();
        Utils.tooltip();
        Utils.popover();
        Utils.fancybox();
        Utils.searchbox();
        Utils.smooth_scroll();
        Utils.lazy();
        Utils.menu();
        Utils.alert();
        Utils.switch();
        Utils.beforeInstallPwa();
        Utils.modal_install_pwa();
    }
    
    static click()
    {
        const trigger = '[data-trigger="wpiris-click"]';
        
        if ( !Modernizr.touchevents ) {
            let ctrl = 0;
            $( window ).on( 'keydown', function ( e ) {
                if ( e.which == 17 || e.which == 91 ) {
                    ctrl = true;
                }
            } );
            $( window ).on( 'keyup', function () {
                ctrl = false;
            } );
            
            $( document ).on( 'mouseup', trigger, function ( e ) {
                if ( e.which == 1 || e.which == 2 ) {
                    let blank = false;
                    let url   = $( this ).find( "a" ).attr( "href" );
                    if ( e.which == 2 ) {
                        blank = true;
                    } // Clic molette
                    if ( $( this ).find( "a" ).attr( "target" ) == "_blank" ) {
                        blank = true;
                    }
                    if ( blank || ctrl ) {
                        window.open( url, '_blank' );
                    } else {
                        if ( Turbolinks.supported ) {
                            Turbolinks.visit( url );
                            $( document ).trigger( 'wpiris-click' );
                        } else {
                            window.location = url;
                        }
                    }
                    return false;
                }
                return false;
                
            } );
        } else {
            $( document ).on( 'click', trigger, function ( e ) {
                
                let blank = false;
                let url   = $( this ).find( "a" ).attr( "href" );
                if ( $( this ).find( "a" ).attr( "target" ) == "_blank" ) {
                    blank = true;
                }
                if ( blank ) {
                    window.open( url, '_blank' );
                } else {
                    if ( Turbolinks.supported ) {
                        Turbolinks.visit( url );
                        $( document ).trigger( 'wpiris-click' );
                    } else {
                        window.location = url;
                    }
                }
                return false;
                
            } );
        }
    }
    
    static tooltip()
    {
        if ( !Modernizr.touchevents ) {
            // Event tooltip to force close
            $( '[data-toggle="tooltip"]' ).on( 'click', function () {
                $( '.tooltip' ).tooltip( 'hide' );
            } );
        }
    }
    
    static popover()
    {
        $( '[data-toggle="tooltip"]' ).tooltip( { trigger: "hover" } );
        $( '[data-toggle="popover"]' ).popover();
    }
    
    static fancybox()
    {
        
        $( '.hentry a:has(>img):not([target="_blank"]):not("[nofancybox]")' ).attr( 'data-fancybox', 'fancybox' );
        
        $( '[data-fancybox="fancybox"]' ).fancybox( {
                                                        buttons: [
                                                            "zoom",
                                                            "slideShow",
                                                            "close"
                                                        ],
                                                        lang: "fr",
                                                        i18n: {
                                                            fr: {
                                                                CLOSE: Theme.translate.fancyboxClose,
                                                                NEXT: Theme.translate.fancyboxNext,
                                                                PREV: Theme.translate.fancyboxPrev,
                                                                ERROR: Theme.translate.fancyboxError,
                                                                PLAY_START: Theme.translate.fancyboxStart,
                                                                PLAY_STOP: Theme.translate.fancyboxStop,
                                                                FULL_SCREEN: Theme.translate.fancyboxFullScreen,
                                                                THUMBS: Theme.translate.fancyboxThumbs,
                                                                DOWNLOAD: Theme.translate.fancyboxDownload,
                                                                SHARE: Theme.translate.fancyboxShare,
                                                                ZOOM: Theme.translate.fancyboxZoom
                                                            },
                                                        },
                                                        caption: function ( instance, item ) {
                                                            let caption = $( this ).closest( 'figure' ).find( 'figcaption' ).html();
                
                                                            if ( !caption ) {
                                                                caption = $( this ).find( 'img' ).attr( 'title' );
                                                            }
                
                                                            if ( !caption ) {
                                                                caption = $( this ).find( 'img' ).attr( 'alt' );
                                                            }
                
                                                            return caption;
                                                        }
                                                    } );
    }
    
    static searchbox()
    {
        const trigger  = '[data-trigger="wpiris-search-layout"]',
              close    = '[data-trigger="wpiris-search-layout-close"]',
              $element = $( '[data-id="wpiris-search-layout"]' );
        
        $( document ).on( 'click', trigger, function ( e ) {
            e.preventDefault();
            $element.fadeIn().find( 'input' ).focus();
        } );
        
        $( document ).on( 'click', close, function ( e ) {
            e.preventDefault();
            $element.fadeOut();
        } );
    }
    
    static smooth_scroll()
    {
        const trigger = '[data-trigger="wpiris-scroll"]',
              shift   = $( 'header' ).height();
        
        $( document ).on( 'click', trigger, function ( e ) {
            e.preventDefault();
            
            const target   = $( this ).attr( "href" ) != '' ? $( this ).attr( "href" ) : $( this ).attr( "data-href" );
            const duration = 1000;
            
            $( 'html, body' ).animate( {
                                           scrollTop: $( target ).offset().top - shift
                                       }, duration );
            
        } );
    }
    
    static lazy()
    {
        let lazyLoadInstance = new LazyLoad( {
                                                 elements_selector: '[data-src], [data-bg]'
                                             } );
    }
    
    static menu()
    {
        const trigger  = '[data-trigger="wpiris-toggle-menu"]',
              $element = $( '[data-id="wpiris-menu"]' );
        
        $( document ).on( 'click', trigger, function ( e ) {
            e.preventDefault();
            $element.toggleClass( 'open' );
            $( trigger ).toggleClass( 'open' );

            $(".popover").hide();

            if ( $element.hasClass( 'open' ) ) {
                $( document ).on( 'click', '.main', function ( e ) {
                    $element.removeClass( 'open' );
                    $( trigger ).removeClass( 'open' );
                } );
            }
        } );
        
    }
    
    static alert()
    {
        const close    = '[data-trigger="wpiris-alert-close"]',
              $element = $( '[data-id="wpiris-alert"]' ),
              position = $element.attr( 'data-position' );
        
        if ( $element.length > 0 ) {
            if ( position ) {
                let positions = position.split( ' ' );
                $element.css( {
                                  "top": positions[ 0 ],
                                  "right": positions[ 1 ],
                                  "bottom": positions[ 2 ],
                                  "left": positions[ 3 ],
                              } );
            }
        }
        
        $( document ).on( 'click', close, function ( e ) {
            e.preventDefault();
            $element.toggleClass( 'close' );
        } );
    }
    
    static switch()
    {
        const trigger     = '[data-trigger="wpiris-switch"]',
              $element    = $( '[data-id="wpiris-switch-result"]' ),
              triggerMode = '[data-switch-mode]';
        
        if ( trigger.length > 0 ) {
            $( document ).on( 'click', trigger, function ( e ) {
                e.preventDefault();
                $( this ).toggleClass( 'switch--checked' );
                
                if ( $element.length > 0 ) {
                    $element.toggleClass( 'switchIsChecked' );
                }
                
            } );
        }
        
        if ( triggerMode.length > 0 && $element.length > 0 ) {
            $( document ).on( 'click', triggerMode, function ( e ) {
                let value = $( this ).data( 'switch-mode' );
                if ( value == 0 ) {
                    $element.removeClass( 'switchIsChecked' );
                    $( trigger ).removeClass( 'switch--checked' );
                } else if ( value == 1 ) {
                    $element.addClass( 'switchIsChecked' );
                    $( trigger ).addClass( 'switch--checked' );
                }
            } );
        }
    }

    static beforeInstallPwa() {
        window.addEventListener('beforeinstallprompt', function (event) {
            const installPromptEvent = event;
            const installPwaElement = $('[data-trigger=install-pwa]');
            if( installPwaElement.length > 0 ) {
                installPwaElement.addClass( 'installable-active' );
                installPwaElement.on( 'click', () => {
                    installPromptEvent.prompt();
                    installPromptEvent.userChoice.then( (choice) => {
                        if(choice.outcome === 'accepted') {
                            // a2hs is accepted
                        } else {
                            // a2hs is dismissed
                        }
                        installPwaElement.removeClass( 'installable-active' );
                    });
                } );
            }
        } );
    }
    
    static modal_install_pwa() {
        
        const trigger = '[data-trigger="modal-install-pwa"]';
        
        $( document ).on( 'click', trigger, function ( e ) {
            $( '#modal-install-pwa' ).modal( 'show' );
        });

        const instScrollViewInstallPwa = mobiscroll.scrollview( '[data-id="install-pwa-list"]', {
            layout: 1,
            mousewheel: true,
            paging: true,
            onAnimationStart: function (event, inst) {
                let selectedIndex = Math.abs( Math.round( -(event.destinationX / inst.contWidth) ) ) + 1;
                $('[data-id="install-pwa-list-1"]').removeClass('active');
                $('[data-id="install-pwa-list-2"]').removeClass('active');
                $(`[data-id="install-pwa-list-${selectedIndex}"]`).addClass('active');
            }
        } );

        $('[data-id="install-pwa-list-1"]').on('click', function(e) {
            e.preventDefault();
            instScrollViewInstallPwa.navigate( $('[data-id-install-pwa-list="1"]') );
        })
        $('[data-id="install-pwa-list-2"]').on('click', function(e) {
            e.preventDefault();
            instScrollViewInstallPwa.navigate( $('[data-id-install-pwa-list="2"]') );
        })
    }
}
