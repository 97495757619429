import 'jquery-match-height/dist/jquery.matchHeight-min';
import Utils from '@nestor/js/services/utils';
import Swiper from "swiper";

export default class WpetList {
    static #element         = '[data-id="bn-list-mobile"]';
    static #element_item    = '[data-id="bn-item"]';
    static #slider_offers   = '';
    static #posts_per_page  = $(WpetList.#element_item).length;
    static #current_results = WpetList.#posts_per_page;

    static #slider_offers_settings = {
        slidesPerView: 1.25,
        spaceBetween: 20,
        mousewheel: {
            forceToAxis: true,
        },
        grabCursor: true,
        on: {
            init: function () {
                $( window ).bind( 'wpet_map_zoomchanged wpet_map_results', function () {
                    WpetList.active_marker_slide_item( 0 );
                } );
            },
            slideChange: function ( slider ) {
                WpetList.slider_change();
            },
        },
    };

    constructor()
    {
        WpetList.view_mode();
        WpetList.match_height();
        WpetList.filters();
        WpetList.link_offer();
        WpetList.offer_meta_mobile();
        WpetList.initialize_trigger();
        WpetList.preview_single();
        WpetList.click_marker_navigate();
        WpetList.ownerFormHandler();
        if ( window.matchMedia( "(max-width: 650px)" ).matches ) {
            WpetList.init_slider();
        }
        WpetList.no_booking_offers();
    }

    static ownerFormHandler() {
        $('[data-modal-contact-wrapper]').on('show.bs.modal', (e) => {
            $('[data-modal-contact-wrapper]').find('[data-title-modal-contact-dynamic]').html($(e.relatedTarget).attr( 'data-title-ot' ));
            $('[data-modal-contact-wrapper]').find('input#field_contactprestataire').val($(e.relatedTarget).attr( 'data-mail-owner' ));
        })
    }

    static view_mode()
    {
        const trigger  = '[data-trigger="wpet-mode"]',
              $element = $( '[data-id="wpet-list"]' );

        if ( trigger.length > 0 ) {
            $( document ).on( 'click', trigger, function ( e ) {
                e.preventDefault();

                const mode = $( this ).attr( 'data-wpet-switch-mode' );
                $element.attr( 'data-wpet-current-mode', mode );

                WpetList.switch_slider();

                window.wpet_map_object.mapObject.resize();
                window.wpet_map_object.mapObject.fitBounds( window.wpet_map_object.bounds );
            } );
        }
    }

    static number_results( data )
    {
        const $element = $( '[data-id="wpet-list-total-offer"]' );
        $element.text( data.total_global );
    }

    static number_current_engine()
    {
        const filters_total = $( '[data-filters-total]' ).data( 'filters-total' ),
              $element      = $( '[data-id="wpet-list-total-current-engine"]' );

        if ( filters_total > 0 ) {
            $element.text( '(' + filters_total + ')' );
        } else {
            $element.text( '' );
        }
    }

    static match_height()
    {
        const $element = $( '[data-mh]' );

        if ( $element.length > 0 ) {
            $element.matchHeight();
        }
    }

    static filters()
    {
        let trigger  = '[data-trigger="wpet-list-filters-button"]',
            close    = '[data-trigger="wpet-list-results-filters-list-close"]',
            $element = $( 'body' );

        $( document ).on( 'click', trigger, function ( e ) {
            e.preventDefault();
            $element.addClass( 'isWpetFiltersOpen' );
        } );

        $( document ).on( 'click', close, function ( e ) {
            e.preventDefault();
            $element.removeClass( 'isWpetFiltersOpen' );
        } );

        $( document ).on( 'wpet_ajax_build', function () {
            $element.removeClass( 'isWpetFiltersOpen' );
        } );
    }

    static link_offer()
    {
        const trigger = '[data-trigger="wpet-click"]';

        if ( trigger.length > 0 ) {
            $( document ).on( 'click', trigger, ( e ) => {
                if ( !$( e.target ).hasClass( 'slick-arrow' ) ) {
                    window.location = $( e.currentTarget ).parents( '[data-id="wpet-offer"]' ).find( '[data-id="wpet-offer-link"]' ).attr( 'href' );
                }
            } );
        }
    }

    static offer_meta_mobile()
    {
        const trigger = '[data-trigger="wpet-offer-mobile-button"]',
              element = '[data-id="wpet-offer-mobile-list"]';

        $( document ).on( 'click', trigger, function ( e ) {
            e.preventDefault();
            $( this ).parents( '[data-id="wpet-offer"]' ).find( element ).toggle();
        } );
    }

    static form_select()
    {
        $( 'select' ).parent().not( '.formSelect' ).children( 'select' ).wrap( '<div class="formSelect"></div>' );
    }

    static back_to_top()
    {
        $( 'html, body' ).animate( {
                                       scrollTop: $( '[data-id="wpet-list"]' ).offset().top - 100
                                   }, 300 );
    }

    static initialize_trigger()
    {
        $( document ).on( 'wpet_ajax_build', ( e, data ) => {
            WpetList.form_select();
            WpetList.match_height();
            Utils.lazy();
            Utils.tooltip();
            Utils.popover();
            WpetList.back_to_top();
            WpetList.number_results( data );
            WpetList.number_current_engine();
            WpetList.no_booking_offers();
        } );

        $( document ).on( 'wpet_ajax_navigation', () => {
            WpetList.match_height();
            Utils.lazy();
        } );

        $( document ).on( 'wpet_ajax_build wpet_ajax_only_form', () => {
            WpetList.form_select();
            WpetList.no_booking_offers();
        } );

        if ( window.matchMedia( "(max-width: 650px)" ).matches && $( '[data-id="wpet-list"]' ).attr( 'data-wpet-current-mode' ) != "list" ) {
            $( document ).on( 'wpet_ajax_build wpet_ajax_only_form', () => {
                // Update swiper slides & return to first
                if( WpetList.#slider_offers){
                    $(WpetList.#element).find(WpetList.#element_item).addClass('swiper-slide');
                    WpetList.#slider_offers.updateSlides();
                    WpetList.#slider_offers.slideTo(0);
                }
                WpetList.active_marker_slide_item( 0 );
            } );
        }

        $( document ).on( 'wpet_map_ready', () => {
            WpetList.marketplace_tooltip_enable();
        } );

    }

    static preview_single()
    {
        let trigger  = '[data-link-preview]',
            $element = $( '[data-load-preview-single-wpet]' ),
            close    = '[data-trigger="wpet-close-preview"]';

        $( document ).on( 'click', trigger, function ( e ) {

            if ( !$( e.target.offsetParent ).hasClass( 'cardItemPreview' ) ) {

                e.preventDefault();

                const post_id = $( this ).attr( "data-identifiant" );

                $element.addClass( "open loading" );
                $element.html( "" );

                $.ajax( {
                            url: Theme.preview_single_wpet,
                            method: "POST",
                            data: {
                                'post_id': post_id,
                            },
                        } ).done( function ( data ) {

                    $element.append( data.result ).removeClass( "loading" );

                    $( document ).on( 'click', close, function ( e ) {

                        e.preventDefault();
                        $element.removeClass( "open" );
                        $element.html( "" );
                    } );

                } );

            }
        } );
    }

    static init_slider()
    {
        if ( $(WpetList.#element_item).length > 1 ) {
            WpetList.add_slide_item_data();
            $(WpetList.#element).addClass('swiper-container');
            $(WpetList.#element).find(WpetList.#element_item).wrapAll('<div class="swiper-wrapper" data-id="bn-list-wrapper"></div>').addClass('swiper-slide');
            WpetList.#slider_offers = new Swiper(WpetList.#element, WpetList.#slider_offers_settings);
        }
    }

    static switch_slider(){
        $(document).find('[data-id="bn-list-wrapper"]').toggleClass('swiper-wrapper swiper-wrapper-disabled');
        $(document).find(WpetList.#element_item).toggleClass('swiper-slide');
    }

    static slider_change( selected_index = WpetList.#slider_offers.activeIndex  )
    {
        if ( selected_index >= WpetList.#current_results - 1 ) {
            $( "[data-id='wpet-list']" ).addClass( 'loading' );
            WpetList.#current_results += WpetList.#posts_per_page;
            wpet_public.wrapper_wpet_offers.infinitescroll( 'resume' );
            wpet_public.wrapper_wpet_offers.infinitescroll( 'retrieve' );

            $( document ).on( 'wpet_ajax_navigation', () => {
                if ( $( '[data-id="wpet-list"]' ).attr( 'data-wpet-current-mode' ) != "list" ) {
                    WpetList.init_slider();
                    setTimeout( function () {
                        WpetList.#slider_offers.navigate( $( '[data-slider-item="' + selected_index + '"]' ) );
                        $( "[data-id='wpet-list']" ).removeClass( 'loading' );
                    }, 1000 );

                }
            } );

        } else {
            WpetList.active_marker_slide_item( selected_index );
        }

    }

    static active_marker_slide_item( ref )
    {
        $( '[data-id="bn-item"]' ).trigger( 'wpet-inactive' );
        let element = $( '[data-slider-item="' + ref + '"]' );
        element.trigger( 'wpet-active' );
    }

    static click_marker_navigate()
    {
        $( document ).on( 'wpet_map_popup_open', function ( event, data ) {
            let current_ref = $( "h3 a[href='" + data.id + "']" ).parents( '[data-id="bn-item"]' );
            WpetList.#slider_offers.navigate( current_ref );
            $(WpetList.#element_item).trigger( 'wpet-inactive' );
            current_ref.trigger( 'wpet-active' );
        } );

    }

    static add_slide_item_data()
    {
        let $element = $( '[data-id="bn-item"]' );
        let number   = 0;

        $element.removeAttr( 'data-slider-item' );

        $element.each( function () {
            $( this ).attr( 'data-slider-item', number );
            number++;
        } );
    }


    static no_booking_offers()
    {
        let no_offer_booking_online = false;
        let count_booking_offers    = 0;
        $( '[data-id="wpet-no-booking-message"]' ).remove();
        $.each( $( '.wpetOffer' ), function ( i, e ) {
            if ( (0 === $( this ).find( '.cardItemContentOpening--available' ).length) && (0 === $( '[data-id="wpet-no-booking-message"]' ).length) ) {
                if ( 0 === i ) {
                    no_offer_booking_online = true;
                }
                if ( no_offer_booking_online == false ) {
                    $( '<p data-id="wpet-no-booking-message" class="wpetListNoBookingMessage">' + Theme.translate.wpet_no_booking + '</p>' ).insertBefore( $( this ) );
                    return false;
                }
            }

            if ( $( this ).find( '.cardItemContentOpening--available' ).length > 0 ) {
                count_booking_offers++;
            }
        } );

        if ( $( '[data-wpet-search="martetplace"]' ).length > 0 ) {
            if ( no_offer_booking_online == true && count_booking_offers == 0 ) {
                $( '.wpetListResultsOffers' ).prepend( '<p data-id="wpet-no-booking-message" class="wpetListNoBookingMessage">' + Theme.translate.wpet_no_booking + '</p>' );
            }
        }
    }

    static marketplace_tooltip_enable()
    {
        if ( $( '[data-wpet-search="martetplace"]' ).length > 0 ) {
            if ( typeof window.IRIS.wpet_marketplace !== 'undefined' ) {
                window.IRIS.wpet_marketplace.tooltip_enable = true;
            }
        }
    }



};
