export default class Speech {
    
    static text = '';
    
    static autosearch = false;
    
    static autosearchDelay = 2000;
    
    static placeholder = '';
    
    static recognition = false;
    
    static recognizing = false;
    
    static ignoreEnd = false;
    
    constructor()
    {
        this.bindEvents();
    }
    
    bindEvents()
    {
        const voiceButton = $( '[data-speech-button]' );
        if ( voiceButton.length > 0 ) {
            
            if ( !('webkitSpeechRecognition' in window) ) {
                voiceButton.hide();
            } else {
                
                const autoseatch = voiceButton.attr( '[data-speech-autosearch]' );
                if ( typeof autoseatch !== 'undefined' && autoseatch === "1" ) {
                    Speech.autosearch = true;
                }
                Speech.init();
                
                voiceButton.on( 'click', function ( event ) {
                    event.preventDefault();
                    Speech.start();
                } );
            }
            
        }
    }
    
    static init()
    {
        Speech.placeholder = $( '[data-speech-text]' ).attr( 'placeholder' );
        
        const recognition          = new webkitSpeechRecognition();
        recognition.continuous     = true;
        recognition.interimResults = true;
        recognition.lang           = navigator.language || navigator.userLanguage;
        
        recognition.onstart = function ( event ) {
            Speech.recognizing = true;
            
            //add class active
            $( '[data-speech-button]' ).addClass( 'active' );
            
            $( '[data-speech-text]' ).attr( 'placeholder', $( '[data-speech-text]' ).attr( 'data-wpet-speech-start-placeholder' ) );
        };
        
        recognition.onstop = function ( event ) {
            $( '[data-speech-text]' ).attr( 'placeholder', Speech.placeholder );
            $( '[data-speech-button]' ).removeClass( 'active' );
        };
        
        recognition.onerror = function () {
            //add class active
            $( '[data-speech-button]' ).removeClass( 'active' );
            Speech.ignoreEnd = true;
        };
        
        recognition.onend = function () {
            Speech.recognizing = false;
            if ( Speech.ignoreEnd ) {
                return;
            }
            
            $( '[data-speech-text]' ).attr( 'placeholder', Speech.placeholder );
            
            $( '[data-speech-button]' ).removeClass( 'active' );
            
            if ( !Speech.text ) {
                return;
            }
            
            if ( window.getSelection ) {
                window.getSelection().removeAllRanges();
                var range = document.createRange();
                range.selectNode( document.querySelector( '[data-speech-text]' ) );
                window.getSelection().addRange( range );
            }
        };
        
        recognition.onresult = function ( event ) {
            var interimTranscript = '';
            if ( typeof (event.results) == 'undefined' ) {
                recognition.onend = null;
                recognition.stop();
                return;
            }
            for ( var i = event.resultIndex; i < event.results.length; ++i ) {
                if ( event.results[ i ].isFinal ) {
                    Speech.text += event.results[ i ][ 0 ].transcript;
                } else {
                    Speech.tex += event.results[ i ][ 0 ].transcript;
                }
            }
            $( '[data-speech-text]' ).val( Speech.text );
            Speech.checkEnd( Speech.text );
        };
        
        Speech.recognition = recognition;
    }
    
    static start()
    {
        if ( Speech.recognizing ) {
            Speech.recognition.stop();
            return;
        }
        Speech.recognition.start();
    }
    
    static checkEnd( text )
    {
        setTimeout( function () {
            if ( text === Speech.text && text.length > 1 ) {
                setTimeout( function () {
                    $( '[data-speech-text]' ).closest( 'form' ).submit();
                } );
            }
        }, Speech.autosearchDelay );
    }
}