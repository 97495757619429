import Swiper from 'swiper';

export default class Slider {

	static #element = '[data-id="bn-list"]';
	static #item = '[data-id="bn-item"]';
	static #element_collection = '[data-id="bn-collection"]';
	static #trigger = '[data-trigger="bn-mode-view"]';
	static #cards_slider = [];
	static #cards_slider_settings = {
		slidesPerView: 4.15,
		spaceBetween: 20,
		pagination: {
			el: "#swiper-pagination",
			type: 'bullets',
			clickable: true
		},
		navigation: {
			nextEl: '#swiper-next',
			prevEl: '#swiper-prev',
		},
		breakpoints: {
			0: {
				slidesPerView: 1.85,
				spaceBetween: 10
			},
			650: {
				slidesPerView: 3,
				spaceBetween: 20
			},
			1000: {
				slidesPerView: 3.5,
				spaceBetween: 20
			},
			1600: {
				slidesPerView: 4.15,
			}
		},
		mousewheel: {
			forceToAxis: true,
		},
		grabCursor: true,

	};

	constructor() {
		Slider.init_slider();

		if ($(Slider.#trigger).length > 0) {
			Slider.slider_switch_mode();
		}
	}

	static init_slider(id = null) {
		if(id){
			$('#' + id).addClass('swiper-container').data('disabled', null);
			$('#' + id).find(Slider.#item).wrapAll('<div class="swiper-wrapper"></div>').addClass('swiper-slide');
			Slider.#cards_slider[id] = new Swiper('#' + id, Slider.#cards_slider_settings);
		}else {
			$(Slider.#element).each(function (index, element) {
				var id = $(element).attr('id');
				$(element).addClass('swiper-container');
				$(element).find(Slider.#item).wrapAll('<div class="swiper-wrapper"></div>').addClass('swiper-slide');
				Slider.#cards_slider[id] = new Swiper('#' + id, Slider.#cards_slider_settings);
			});
		}
	}

	static destroy_slider(id) {
		Slider.#cards_slider[id].destroy();
		delete Slider.#cards_slider[id];
		$('#' + id).removeClass('swiper-container').data('disabled', true);
		$('#' + id).find(Slider.#item).removeClass('swiper-slide').unwrap();
	}

	static slider_switch_mode() {
		$(document).on('click', Slider.#trigger, function (e) {
			e.preventDefault();
			let id_slider = $(this).parents(Slider.#element_collection).find(Slider.#element).attr('id');
			let isDisabled = $('#' + id_slider).data('disabled');

			$(this).parent().find('a').toggleClass('hide');
			$('.popover').popover('hide');
			$('[data-id="bn-item"]').removeClass('cardItem--hover');

			if (isDisabled) {
				Slider.init_slider(id_slider);
			} else {
				Slider.destroy_slider(id_slider);
			}
		});
	}
}
