import Utils from '@nestor/js/services/utils';
import Ux from '@nestor/js/services/ux.js';
import Speech from '@nestor/js/services/speech.js';
import Overlay from '@nestor/js/services/overlay.js';
import Form from '@nestor/js/services/form';
import Slider from '@nestor/js/services/slider.js';
import ClearCache from '@nestor/js/services/clear-cache';
import WpetList from '@nestor/js/wpet/list';

export default {
    init()
    {
        new Utils;
        new WpetList();
        new Ux.ripple_effect();
        new Ux.turbolinks();
        new Speech();
        new Form();
        new Overlay();
        new Slider();
        ClearCache.font_icon();
    },
    finalize()
    {
        if ($('#modal-preferences').length > 0) {
            const searchParams = new URLSearchParams(window.location.search) //récupère les params dans l'url
            if (searchParams.has('open_preferences')) { //teste ta variable
                let param = searchParams.get('open_preferences');
                //if ("true" === param) { //teste la valeur attendue
                    $('#modal-preferences').modal('show'); //affiche la modale correspondante (a mettre dans le footer de préférence)
                //}
            }
        }
    },

};
