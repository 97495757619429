export default class ClearCache {
	constructor()
	{
		ClearCache.font_icon();
	}
	/**
	 * Supprime le cache de la font-icon en lien avec PWA
	 */
	static font_icon()
	{
		caches.keys().then((keys) => {
			return Promise.all(keys.map(key => {
				//console.log(key);
				//if (/^(pwp-([a-zA-Z0-9]{8})-static)/.test(key)) {
					return caches.delete(key);
				//}
			}))
		})
	}
}
