import mobiscroll from '@nestor/js/mobiscroll.javascript.min';


export default class Form {
	
	constructor()
	{
		Form.input();
		Form.select();
		// Form.calendar();
	}
	
	static input()
	{
		let $elements = $( '.frm_form_field [type="checkbox"], .frm_form_field [type="radio"]' );
		
		$( $elements ).each( function ( index, element ) {
			let id         = $( element ).attr( 'name' ) + '-' + index;
			let label_text = $( element ).siblings( '.wpcf7-list-item-label' ).text();
			let label      = '<label class="form-label--outer" for="' + id + '">' + label_text + '</label>';
			
			$( element ).siblings( '.wpcf7-list-item-label' ).remove();
			$( element ).parent().append( label );
			$( element ).attr( 'id', id );
		} );
	}
	
	static select()
	{
		$( 'select' ).parent().not( '.formSelect' ).children( 'select' ).wrap( '<div class="formSelect"></div>' );
	}
	
	static calendar()
	{
		mobiscroll.calendar( 'input[type="date"]', {
			lang: 'fr'
		} );
	}
}
