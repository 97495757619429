import '@nestor/scss/app-front-page.scss';
import '@nestor/js/bootstrap.bundle';
import Router from '@nestor/js/utils/router.js';
import home from '@nestor/js/routes/home.js';

const routes = new Router( {
                               home
                           } );

document.addEventListener( "DOMContentLoaded", () => {
    routes.loadEvents();
} );
